import http from "./axios";

export const queryPage = (data) => {
  return http.get("/incrSyncTask/incrSyncTaskPage", data);
};

export const taskStateList = () => {
  return http.get("/incrSyncTask/taskStateList");
};

export const taskEventList = () => {
  return http.get("/incrSyncTask/taskEventList");
};

export const incrSuccessCount = (data, cancelToken) => {
  return http.get("/incrSyncTask/incrSuccessCount", data, cancelToken);
};

export const enableSample = (data) => {
  return http.get("/incrSyncTask/enableSample", data);
};

export const getSample = (data) => {
  return http.get("/incrSyncTask/getSample", data);
};

export const addData = (data) => {
  return http.json_post("/incrSyncTask/addIncrSyncTask", data);
};

export const editData = (data) => {
  return http.json_post("/incrSyncTask/editIncrSyncTask", data);
};

export const deleteData = (data) => {
  return http.json_post("/incrSyncTask/deleteIncrSyncTask", data);
};

export const start = (data) => {
  return http.json_post("/incrSyncTask/start", data);
};

export const stop = (data) => {
  return http.json_post("/incrSyncTask/stop", data);
};
