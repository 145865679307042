<template>
  <div class="fullSync">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>任务名：</span>
        <a-input
          placeholder="请输入任务名"
          v-model.trim="taskName"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        <span>状态：</span>
        <a-select
          v-model="status"
          placeholder="请选择状态"
          allowClear
          @change="query(1)"
        >
          <a-select-option v-for="i in stateList" :key="i.code" :value="i.code">
            {{ i.desc }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button
          type="primary"
          @click="add"
          icon="plus"
          v-if="this.parentTaskId !== -1"
          >新增</a-button
        >
      </div>
    </div>

    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
    >
      <span slot="sumCount" slot-scope="text, record" style="width: 100%">
        <a-tooltip>
          <template slot="title">
            接收数: {{ record.receiveCount }}<br />
            处理数: {{ record.realCount }}<br />
            总数:
            {{
              record.sumCount == 0 && record.realCount > 0
                ? "计算中"
                : record.sumCount
            }}<br />
            <template v-if="record.speed !== 0">
              速率: {{ record.speed }}条/s<br />
              预计结束时间还有:
              {{
                record.sumCount == 0 && record.realCount > 0
                  ? "00:00:00"
                  : record.excessTime
              }}
            </template>
          </template>
          <a-progress
            :success-percent="record.countRate"
            :percent="record.receiveCountRate"
            size="small"
            style="width: 200px"
          />
        </a-tooltip>
      </span>
      <span slot="statusDesc" slot-scope="text, record">
        <a-tag :color="record.statusColor">
          {{ record.statusDesc }}
        </a-tag>
      </span>
      <span slot="errorCount" slot-scope="text, record">
        <a href="javascript:;" style="color: #ff4d4f">
          {{ text }}
        </a>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="modifyClick(record)">修改</a-button>
        <a-divider type="vertical" />
        <template v-if="executeType == 'NO_DELAY'">
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="startTask(record)"
            :disabled="record.status !== 'DRAFT'"
          >
            <template slot="title">确认是否开始任务</template>
            <a href="javascript:;" :disabled="record.status !== 'DRAFT'">
              开始
            </a>
          </a-popconfirm>
          <a-divider type="vertical" />
        </template>
        <template v-else>
          <a-button type="link" @click="subtask(record, 1)"
            >子任务列表</a-button
          >
          <a-divider type="vertical" />
        </template>
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="executeType == 'NO_DELAY' && !parentTaskId">
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="copyTask(record)"
              >
                <template slot="title">确认是否复制任务</template>
                <a href="javascript:;"> 复制任务 </a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item
              :disabled="
                record.status !== 'SUCCESS' && record.status !== 'ERROR'
              "
              v-if="executeType == 'NO_DELAY'"
            >
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="resetTask(record)"
                :disabled="
                  record.status !== 'SUCCESS' && record.status !== 'ERROR'
                "
              >
                <template slot="title">确认是否重置任务</template>
                <a
                  href="javascript:;"
                  style="color: #ff4d4f"
                  :disabled="
                    record.status !== 'SUCCESS' && record.status !== 'ERROR'
                  "
                >
                  重置任务
                </a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item
              :disabled="record.status !== 'RUNNING'"
              v-if="executeType == 'NO_DELAY'"
            >
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="interruptTask(record)"
                :disabled="record.status !== 'RUNNING'"
              >
                <template slot="title">确认是否中断</template>
                <a
                  href="javascript:;"
                  style="color: #ff4d4f"
                  :disabled="record.status !== 'RUNNING'"
                >
                  中断
                </a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item v-if="executeType == 'SCHEDULED'">
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="modifyFullSyncCronTask(record)"
              >
                <template slot="title">
                  确认是否{{ record.status == "STOP" ? "启动" : "停止" }}任务
                </template>
                <a href="javascript:;">
                  {{ record.status == "STOP" ? "启动" : "停止" }}任务
                </a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item
              :disabled="
                record.status == 'SUCCESS' && executeType == 'NO_DELAY'
              "
            >
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteClick(record)"
                :disabled="
                  record.status == 'SUCCESS' && executeType == 'NO_DELAY'
                "
              >
                <template slot="title">确认是否删除</template>
                <a
                  href="javascript:;"
                  style="color: #ff4d4f"
                  :disabled="
                    record.status == 'SUCCESS' && executeType == 'NO_DELAY'
                  "
                >
                  删除
                </a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <!-- 新增 -->
    <a-modal
      :title="title"
      v-if="addKeyVisible"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="任务名:">
          <a-input v-model="updateData.taskName" placeholder="请输入任务名" />
        </a-form-item>
        <a-form-item label="cron表达式:" v-if="executeType == 'SCHEDULED'">
          <a-select
            v-model="updateData.cronSelect"
            placeholder="请选择cron表达式"
            style="width: 150px"
          >
            <a-select-option
              v-for="item in cronList"
              :key="item.id"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-input
            v-if="updateData.cronSelect == ''"
            v-model="updateData.cron"
            placeholder="请输入cron表达式"
            style="width: 145px; margin-left: 5px"
          />
        </a-form-item>
        <a-form-item label="数据量:">
          <a-switch
            checked-children="全表同步"
            un-checked-children="部分同步"
            v-model="updateData.typeBol"
          />
        </a-form-item>
        <a-form-item v-if="!updateData.typeBol">
          <span slot="label">
            条件
            <a-tooltip>
              <template slot="title">
                请输入where后面的条件语句,支持日期表达式，如： <br />
                ${yyyy-MM-dd} <br />
                ${yyyy-MM-dd:+1d} <br />
              </template>
              <a-icon type="question-circle" style="color: #1879ff" />
            </a-tooltip>
          </span>
          <a-textarea
            v-model="updateData.condition"
            placeholder="请输入条件"
            :rows="5"
          />
        </a-form-item>
        <a-form-item label="同步类型:">
          <a-select
            v-model="updateData.syncType"
            placeholder="请选择同步类型"
            @change="getRuleList()"
          >
            <a-select-option
              v-for="(item, index) in syncTypeList"
              :key="index"
              :value="item.code"
            >
              {{ item.source }} -> {{ item.sink }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="规则:">
          <a-select
            v-model="ruleId"
            placeholder="请选择同步类型"
            style="width: 75%"
            :dropdownMatchSelectWidth="false"
          >
            <a-select-option
              v-for="item in ruleList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-button
            type="link"
            style="margin-left: 5px"
            :disabled="!ruleId"
            @click="setRule(false)"
          >
            修改
          </a-button>
          <a-button type="link" style="margin-left: 5px" @click="setRule(true)">
            新增
          </a-button>
        </a-form-item>
        <a-form-item label="备注:" v-if="title == '修改'">
          <a-input v-model="updateData.remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">
            {{
              updateData.status == "DRAFT" || executeType == "SCHEDULED"
                ? "取消"
                : "关闭"
            }}
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            v-if="updateData.status == 'DRAFT' || executeType == 'SCHEDULED'"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <!-- 子任务列表 -->
    <a-modal
      title="子任务列表"
      v-model="subtaskVisible"
      :maskClosable="false"
      class="action-class"
      width="1000px"
      @cancel="subtaskClose"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          <span>任务名：</span>
          <a-input
            placeholder="请输入任务名"
            v-model.trim="subtaskTaskName"
            @pressEnter="subtask(null, 1)"
            allowClear
            @change="allowClearSubtaskChange"
          />
        </div>
        <div class="searchConditionItem">
          <span>状态：</span>
          <a-select
            v-model="subtaskStatus"
            placeholder="请选择状态"
            allowClear
            @change="subtask(null, 1)"
          >
            <a-select-option
              v-for="i in stateList"
              :key="i.code"
              :value="i.code"
            >
              {{ i.desc }}
            </a-select-option>
          </a-select>
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="subtask(null, 1)" icon="search"
            >查询</a-button
          >
        </div>
      </div>
      <a-table
        :columns="noDelayTableColumns"
        :dataSource="subtaskTableDataSource"
        :pagination="subtaskTablePagination"
        :loading="subtaskLoadingTable"
        size="middle"
        :rowKey="(record) => record.id"
      >
        <span slot="sumCount" slot-scope="text, record" style="width: 100%">
          <a-tooltip>
            <template slot="title">
              实际数: {{ record.realCount }}<br />
              总数: {{ record.sumCount }}
            </template>
            <a-progress
              :percent="record.countRate"
              size="small"
              style="width: 200px"
            />
          </a-tooltip>
        </span>
        <span slot="statusDesc" slot-scope="text, record">
          <a-tag :color="record.statusColor">
            {{ record.statusDesc }}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="modifyClick(record)">修改</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              其它 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item :disabled="record.status !== 'DRAFT'">
                <a-popconfirm
                  placement="right"
                  okText="确认"
                  cancelText="取消"
                  @confirm="startTask(record)"
                  :disabled="record.status !== 'DRAFT'"
                >
                  <template slot="title">确认是否开始任务</template>
                  <a href="javascript:;" :disabled="record.status !== 'DRAFT'">
                    开始
                  </a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item
                :disabled="
                  record.status !== 'SUCCESS' && record.status !== 'ERROR'
                "
              >
                <a-popconfirm
                  placement="right"
                  okText="确认"
                  cancelText="取消"
                  @confirm="resetTask(record)"
                  :disabled="
                    record.status !== 'SUCCESS' && record.status !== 'ERROR'
                  "
                >
                  <template slot="title">确认是否重置任务</template>
                  <a
                    href="javascript:;"
                    style="color: #ff4d4f"
                    :disabled="
                      record.status !== 'SUCCESS' && record.status !== 'ERROR'
                    "
                  >
                    重置任务
                  </a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item :disabled="record.status !== 'RUNNING'">
                <a-popconfirm
                  placement="right"
                  okText="确认"
                  cancelText="取消"
                  @confirm="interruptTask(record)"
                  :disabled="record.status !== 'RUNNING'"
                >
                  <template slot="title">确认是否中断</template>
                  <a
                    href="javascript:;"
                    style="color: #ff4d4f"
                    :disabled="record.status !== 'RUNNING'"
                  >
                    中断
                  </a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item :disabled="record.status !== 'DRAFT'">
                <a-popconfirm
                  placement="right"
                  okText="确认"
                  cancelText="取消"
                  @confirm="deleteClick(record)"
                  :disabled="
                    record.status !== 'DRAFT' && executeType == 'NO_DELAY'
                  "
                >
                  <template slot="title">确认是否删除</template>
                  <a
                    href="javascript:;"
                    style="color: #ff4d4f"
                    :disabled="
                      record.status !== 'DRAFT' && executeType == 'NO_DELAY'
                    "
                  >
                    删除
                  </a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="subtaskVisible = false"> 关闭 </a-button>
        </div>
      </template>
    </a-modal>

    <convert-rule-modal
      :title="title"
      :show="show"
      :ruleId="ruleId"
      :addInfo="addInfo"
      :disabled="updateData.status !== 'DRAFT' && executeType !== 'SCHEDULED'"
      type="full"
      @close="close"
      @submit="submit"
    ></convert-rule-modal>
  </div>
</template>
<script>
import * as api from "../lib/fullSync.js";
import { syncRulePage, getSyncTypeList } from "../lib/syncRuleList";
import convertRuleModal from "./convertRuleModal.vue";
import axios from "axios";

export default {
  name: "fullSyncTemplate",
  components: { convertRuleModal },
  props: {
    executeType: String,
    parentTaskId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      title: "",
      taskName: "",
      status: undefined,
      stateList: [],
      syncTypeList: [],
      groupId: "",
      addKeyVisible: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      noDelayTableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "任务名",
          dataIndex: "taskName",
        },
        {
          title: "状态",
          dataIndex: "statusDesc",
          scopedSlots: { customRender: "statusDesc" },
        },
        {
          title: "同步类型",
          dataIndex: "syncType",
        },
        {
          title: "条数",
          dataIndex: "sumCount",
          scopedSlots: { customRender: "sumCount" },
        },
        {
          title: "耗时",
          dataIndex: "time",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      scheduledTableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "任务名",
          dataIndex: "taskName",
        },
        {
          title: "状态",
          dataIndex: "statusDesc",
          scopedSlots: { customRender: "statusDesc" },
        },
        {
          title: "cron表达式",
          dataIndex: "cron",
        },
        {
          title: "同步类型",
          dataIndex: "syncType",
        },
        {
          title: "下次执行时间",
          dataIndex: "nextExecuteTime",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableColumns: [],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      ruleId: 0,
      ruleList: [],
      show: false,
      timer: null,
      addInfo: {},
      cronList: [
        { id: 1, label: "每分钟同步一次", value: "0 * * * * ?" },
        { id: 2, label: "五分钟同步一次", value: "0 */5 * * * ?" },
        { id: 3, label: "十分钟同步一次", value: "0 */10 * * * ?" },
        { id: 4, label: "十五分钟同步一次", value: "0 */15 * * * ?" },
        { id: 5, label: "三十分钟同步一次", value: "0 */30 * * * ?" },
        { id: 6, label: "一小时同步一次", value: "0 0 * * * ?" },
        { id: 7, label: "自定义", value: "" },
      ],
      subtaskVisible: false,
      subtaskId: 1,
      subtaskTaskName: "",
      subtaskStatus: undefined,
      subtaskPageNo: 1,
      subtaskTableDataSource: [],
      subtaskTablePagination: {},
      subtaskLoadingTable: false,
      subtaskTimer: null,
      cancelToken: axios.CancelToken.source(),
    };
  },
  watch: {
    executeType: {
      immediate: true,
      handler(newVal) {
        this.taskName = "";
        this.status = undefined;
        if (newVal == "NO_DELAY") {
          this.tableColumns = this.noDelayTableColumns;
        } else {
          this.tableColumns = this.scheduledTableColumns;
        }
      },
    },
    parentTaskId: {
      handler() {
        this.taskName = "";
        this.status = undefined;
      },
    },
  },
  mounted() {
    this.getStateList();
    this.getSyncTypeList();
  },
  methods: {
    getStateList() {
      if (this.executeType == "NO_DELAY") {
        api.taskStateList().then((res) => {
          if (res.result === 200) {
            this.stateList = res.data;
            this.query(1);
          }
        });
      } else {
        api.scheduledTaskStatusList().then((res) => {
          if (res.result === 200) {
            this.stateList = res.data;
            this.query(1);
          }
        });
      }
    },
    getSyncTypeList() {
      getSyncTypeList().then((res) => {
        if (res.result === 200) {
          this.syncTypeList = res.data.filter((item) => {
            return item.source !== "KAFKA";
          });
        }
      });
    },
    getRuleList(ruleId) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        syncType: this.updateData.syncType,
      };
      syncRulePage(data).then((res) => {
        if (res.result == 200) {
          this.ruleList = res.data.records;
          this.ruleId = ruleId;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        taskName: this.taskName,
        status: this.status,
        executeType: this.executeType,
        parentTaskId: this.parentTaskId,
      };
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              this.queryItemInit(item);
            });
            this.tableDataSource = list;
            let idList = this.tableDataSource
              .filter((item) => {
                return item.status == "RUNNING";
              })
              .map((item) => {
                return item.id;
              });
            if (idList.length > 0) {
              const { token } = this.cancelToken;
              this.queryState(idList, token);
            }
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.loadingTable = false;
        });
    },
    queryItemInit(item) {
      this.getStateDesc(item);
      item.speed = 0;
      item.excessTime = 0;
      if (item.status == "RUNNING") {
        let secondsDifference = this.calculateTimeDifference(
          item.startDate,
          item.endDate
        ).secondsDifference;
        if (item.realCount && secondsDifference) {
          item.speed = Math.round(item.realCount / secondsDifference);
        } else {
          item.speed = 0;
        }
        if (item.speed) {
          let excessMillisecond =
            Math.round((item.sumCount - item.realCount) / item.speed) * 1000;
          item.excessTime = this.excessTimeFormat(excessMillisecond);
        } else {
          item.excessTime = 0;
        }
      }
      item.countRate = Number(
        ((item.realCount / item.sumCount) * 100).toFixed(1)
      );
      item.receiveCountRate = Number(
        ((item.receiveCount / item.sumCount) * 100).toFixed(1)
      );
      if (item.startDate && item.startDate !== "2000-01-01 00:00:00") {
        item.time = this.calculateTimeDifference(
          item.startDate,
          item.endDate
        ).formattedDifference;
      } else {
        item.time = "00:00:00";
      }
    },
    queryState(idList, token) {
      api.queryState({ ids: idList.join(",") }, token).then((res) => {
        if (res.result == 200) {
          let newIdList = [];
          let list = [];
          res.data.forEach((newItem) => {
            if (newItem.status == "RUNNING") {
              newIdList.push(newItem.id);
            }
            list.push(newItem);
          });
          list.forEach((newItem) => {
            this.queryItemInit(newItem);
            this.tableDataSource.forEach((item, index) => {
              if (newItem.id == item.id) {
                this.$set(item, "status", newItem.status);
                this.$set(item, "statusDesc", newItem.statusDesc);
                this.$set(item, "statusColor", newItem.statusColor);
                this.$set(item, "speed", newItem.speed);
                this.$set(item, "excessTime", newItem.excessTime);
                this.$set(item, "time", newItem.time);
                this.$set(item, "realCount", newItem.realCount);
                this.$set(item, "sumCount", newItem.sumCount);
                this.$set(item, "receiveCount", newItem.receiveCount);
                this.$set(item, "countRate", newItem.countRate);
                this.$set(item, "receiveCountRate", newItem.receiveCountRate);
              }
            });
          });

          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          if (newIdList.length > 0) {
            this.timer = setTimeout(() => {
              const { token } = this.cancelToken;
              this.queryState(newIdList, token);
            }, 2000);
          }
        }
      });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    getStateDesc(item) {
      switch (item.status) {
        case "DRAFT":
          item.statusDesc = "草稿";
          item.statusColor = "orange";
          break;
        case "RUNNING":
          item.statusDesc = "同步中";
          item.statusColor = "orange";
          break;
        case "SUCCESS":
          item.statusDesc = "完成";
          item.statusColor = "green";
          break;
        case "ERROR":
          item.statusDesc = "失败";
          item.statusColor = "red";
          break;
        case "STOP":
          item.statusDesc = "停止";
          item.statusColor = "red";
          break;
        case "WAITING":
          item.statusDesc = "等待中";
          item.statusColor = "orange";
          break;
      }
    },
    excessTimeFormat(excessTime) {
      // 转换差值为HH:mm:ss格式
      var hours = Math.floor(excessTime / (1000 * 60 * 60));
      var minutes = Math.floor((excessTime % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((excessTime % (1000 * 60)) / 1000);

      // 格式化输出
      var formattedDifference =
        this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds);

      return formattedDifference;
    },
    calculateTimeDifference(startDate, endDate) {
      // 如果endDate为空，使用当前时间
      if (!endDate || endDate == "2000-01-01 00:00:00") {
        endDate = new Date();
      } else {
        endDate = new Date(endDate);
      }

      startDate = new Date(startDate);

      // 计算差值（毫秒）
      var timeDifference = endDate - startDate;

      // 转换差值为HH:mm:ss格式
      var hours = Math.floor(timeDifference / (1000 * 60 * 60));
      var minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // 格式化输出
      var formattedDifference =
        this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds);

      return {
        formattedDifference,
        secondsDifference: Math.round(timeDifference / 1000),
      };
    },
    // 在个位数前面补零
    pad(number) {
      return (number < 10 ? "0" : "") + number;
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.groupId = val.id;
      this.updateData = {
        taskName: val.taskName,
        condition: val.condition,
        typeBol: val.type == "FULL" ? true : false,
        syncType: val.syncType,
        remark: val.remark,
        status: val.status,
      };
      if (this.executeType == "SCHEDULED") {
        this.$set(this.updateData, "cron", val.cron);
        let cronArr = this.cronList.filter((item) => {
          return item.value == val.cron;
        });
        if (cronArr.length > 0) {
          this.$set(this.updateData, "cronSelect", val.cron);
        } else {
          this.$set(this.updateData, "cronSelect", "");
        }
      }
      this.ruleId = val.syncRuleId;
      this.getRuleList(this.ruleId);
      this.addKeyVisible = true;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.ruleId = undefined;
      api.generateName().then((res) => {
        if (res.result === 200) {
          this.updateData = {
            taskName: res.data.name,
            condition: "",
            typeBol: true,
            syncType: this.syncTypeList[0].code,
            remark: "",
            status: "DRAFT",
            executeType: this.executeType,
          };
          if (this.executeType == "SCHEDULED") {
            this.$set(this.updateData, "cron", "0 */5 * * * ?");
            this.$set(this.updateData, "cronSelect", "0 */5 * * * ?");
          }
          this.getRuleList();
        }
      });
    },
    setRule(add) {
      if (add) {
        this.ruleId = undefined;
        this.addInfo = {
          sourceType: this.updateData.syncType.split("_2_")[0],
          sinkType: this.updateData.syncType.split("_2_")[1],
        };
      }
      this.show = true;
    },
    close() {
      this.show = false;
    },
    submit(ruleId) {
      this.show = false;
      if (ruleId) {
        this.getRuleList(ruleId);
      }
      if (this.title == "修改") {
        this.add_submit();
      }
    },
    // 确定新增
    add_submit() {
      let data = { ...this.updateData };
      data.syncRuleId = this.ruleId;
      if (!data.syncRuleId) {
        this.$message.warning("请设置规则");
        return;
      }
      if (data.cronSelect !== "") {
        data.cron = data.cronSelect;
      }
      if (data.typeBol) {
        data.type = "FULL";
      } else {
        data.type = "PART";
      }

      delete data.status;
      delete data.cronSelect;
      delete data.typeBol;

      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            if (this.subtaskVisible) {
              this.subtask();
            } else {
              this.query();
            }
          }
        });
      }
    },
    allowClearSubtaskChange(e) {
      if (e.target.value) {
        return;
      }
      this.subtask(null, 1);
    },
    subtask(val, index) {
      if (index) {
        this.pageNo = index;
      }
      if (val) {
        this.subtaskId = val.id;
        this.subtaskTaskName = "";
        this.subtaskStatus = undefined;
      }
      let data = {
        pageNo: this.subtaskPageNo,
        pageSize: 10,
        taskName: this.subtaskTaskName,
        status: this.subtaskStatus,
        executeType: "NO_DELAY",
        parentTaskId: this.subtaskId,
      };
      api
        .queryPage(data)
        .then((res) => {
          this.subtaskLoadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              this.getStateDesc(item);
              item.countRate = Number(
                ((item.realCount / item.sumCount) * 100).toFixed(1)
              );
              if (item.startDate && item.startDate !== "2000-01-01 00:00:00") {
                item.time = this.calculateTimeDifference(
                  item.startDate,
                  item.endDate
                ).formattedDifference;
              } else {
                item.time = "00:00:00";
              }
            });
            this.subtaskTableDataSource = list;
            let idList = this.subtaskTableDataSource
              .filter((item) => {
                return item.status == "RUNNING";
              })
              .map((item) => {
                return item.id;
              });
            if (idList.length > 0) {
              const { token } = this.cancelToken;
              this.querySubtaskState(idList, token);
            }
            this.subtaskTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageSubtaskItem(current),
            };
            this.subtaskVisible = true;
          }
        })
        .catch((err) => {
          this.subtaskClose();
          this.subtaskLoadingTable = false;
        });
    },
    querySubtaskState(idList, token) {
      api.queryState({ ids: idList.join(",") }, token).then((res) => {
        if (res.result == 200) {
          let newIdList = [];
          let list = [];
          res.data.forEach((newItem) => {
            if (newItem.status == "RUNNING") {
              newIdList.push(newItem.id);
            } else {
              list.push(item);
            }
          });
          list.forEach((newItem) => {
            this.queryItemInit(newItem);
            this.subtaskTableDataSource.forEach((item, index) => {
              if (newItem.id == item.id) {
                this.subtaskTableDataSource.splice(index, 1, item);
              }
            });
          });
          if (this.subtaskTimer) {
            clearTimeout(this.subtaskTimer);
            this.subtaskTimer = null;
          }
          if (newIdList.length > 0) {
            this.subtaskTimer = setTimeout(() => {
              const { token } = this.cancelToken;
              this.querySubtaskState(newIdList, token);
            }, 2000);
          }
        }
      });
    },
    changePageSubtaskItem(index) {
      this.subtask(null, index);
    },
    subtaskClose() {
      this.cancelToken.cancel();
      this.cancelToken = axios.CancelToken.source();
      if (this.subtaskTimer) {
        clearInterval(this.subtaskTimer);
        this.subtaskTimer = null;
      }
    },
    modifyFullSyncCronTask(val) {
      let data = {
        id: val.id,
        status: val.status == "STOP" ? "WAITING" : "STOP",
      };
      api.modifyFullSyncCronTask(data).then((res) => {
        if (res.result === 200) {
          if (val.status == "STOP") {
            this.$message.success("启动成功");
          } else {
            this.$message.success("停止成功");
          }
          this.query();
        }
      });
    },
    copyTask(val) {
      api.generateName().then((res) => {
        if (res.result === 200) {
          this.title = "新增";
          this.ruleId = val.syncRuleId;
          this.updateData = {
            taskName: res.data.name,
            condition: val.condition,
            typeBol: val.type == "FULL" ? true : false,
            syncType: val.syncType,
            remark: val.remark,
            executeType: this.executeType,
          };
          this.add_submit();
        }
      });
    },
    startTask(val) {
      let data = {
        id: val.id,
      };
      api.start(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("开始成功");
          if (this.subtaskVisible) {
            this.subtask();
          } else {
            this.query();
          }
        }
      });
    },
    resetTask(val) {
      let data = {
        id: val.id,
      };
      api.reset(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("重置成功");
          if (this.subtaskVisible) {
            this.subtask();
          } else {
            this.query();
          }
        }
      });
    },
    interruptTask(val) {
      let data = {
        id: val.id,
      };
      api.interruptTask(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("中断成功");
          if (this.subtaskVisible) {
            this.subtask();
          } else {
            this.query();
          }
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          if (this.subtaskVisible) {
            this.subtask();
          } else {
            this.query();
          }
        }
      });
    },
  },
  beforeDestroy() {
    this.cancelToken.cancel();
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.ant-switch {
  background-color: #52c41a !important;
}
.ant-switch-checked {
  background-color: #1890ff !important;
}
</style>
