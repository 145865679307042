import http from "./axios";

export const queryPage = (data) => {
  return http.get("/fullSyncTask/fullSyncTaskPage", data);
};

export const queryState = (data, cancelToken) => {
  return http.get("/fullSyncTask/queryState", data, cancelToken);
};

export const taskStateList = () => {
  return http.get("/fullSyncTask/taskStateList");
};

export const scheduledTaskStatusList = () => {
  return http.get("/fullSyncTask/scheduledTaskStatusList");
};

export const generateName = () => {
  return http.get("/fullSyncTask/generateName");
};

export const addData = (data) => {
  return http.json_post("/fullSyncTask/addFullSyncTask", data);
};

export const editData = (data) => {
  return http.json_post("/fullSyncTask/editFullSyncTask", data);
};

export const deleteData = (data) => {
  return http.json_post("/fullSyncTask/deleteFullSyncTask", data);
};

export const start = (data) => {
  return http.json_post("/fullSyncTask/start", data);
};

export const interruptTask = (data) => {
  return http.json_post("/fullSyncTask/interruptTask", data);
};

export const reset = (data) => {
  return http.json_post("/fullSyncTask/reset", data);
};

export const modifyFullSyncCronTask = (data) => {
  return http.json_post("/fullSyncTask/modifyFullSyncCronTask", data);
};
